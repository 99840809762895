<template>
  <div class="mt-2 pl-3">
    <v-row class="mb-3"
      ><span class="text-h5 font-weight-light">{{
        region.properties.name
      }}</span></v-row
    >
    <v-row class="mb-3">
      <span class="text-h6">{{ $t("thresholdSettings.setThresholds") }}</span>
    </v-row>
    <v-row class="mb-3 hidden-md-and-down" style="width: 90%">
      <span class="text-body-1 mt-2">{{
        $t("thresholdSettings.thresholdDescription")
      }}</span>
    </v-row>
    <v-row class="mb-3 hidden-lg-and-up" style="width: 90%">
      <span class="text-body-1 mt-2">{{
        $t("thresholdSettings.thresholdDescriptionShort")
      }}</span>
    </v-row>
    <v-row>
      <v-radio-group v-model="thresholdOption" row>
        <v-radio
          :label="$t('thresholdSettings.setRegionThreshold')"
          value="region"
        ></v-radio>
        <v-radio
          :label="$t('thresholdSettings.setLocalThreshold')"
          value="local"
        ></v-radio>
      </v-radio-group>
      <v-autocomplete
        v-if="virtualStations.length && thresholdOption == 'local'"
        style="max-width: 300px;"
        clearable
        :label="$t('thresholdSettings.selectStation')"
        :color="'primary'"
        v-model="vsStation"
        :items="virtualStations"
        :item-text="item => item.name"
        return-object
      ></v-autocomplete>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ThresholdHeader",
  data: () => ({
    thresholdOption: "region",
    vsStation: undefined
  }),
  props: {
    virtualStations: Array,
    region: Object
  },
  watch: {
    thresholdOption() {
      this.$emit("changeMode", this.thresholdOption);
    },
    vsStation() {
      this.$emit("changeSelectedStation", this.vsStation);
    }
  },
  created() {
    this.vsStation = this.virtualStations[0];
  }
};
</script>

<style scoped></style>
