const PlotUtils = {
  createPlotEntry({
    layerId,
    layerType,
    x,
    y,
    steps,
    legendName,
    text = [],
    unit = "",
    mode = "markers",
    plotId = ""
  }) {
    const symbols = [
      "circle",
      "square",
      "diamond",
      "x",
      "triangle",
      "cross",
      "hexagon",
      "diamond-wide"
    ];
    let entry = {
      layerId: layerId,
      layerType: layerType,
      type: "scatter",
      mode: mode,
      marker: {
        size: 7,
        symbol: symbols[Math.floor(Math.random() * symbols.length)]
      },
      x: x,
      y: y,
      steps: steps,
      name: legendName,
      unit: unit,
      plotId: plotId
    };

    let hoverTemplate = `<b>%{y:.2f}</b>`;
    if (text.length !== 0) {
      entry.text = text;
      hoverTemplate += "<br>%{text}";
    }
    entry.hovertemplate = hoverTemplate;
    return entry;
  },
  createRasterLegendName({
    name,
    coordinates = null,
    unit = null,
    tempRes,
    aggregationStep
  }) {
    let legendName = `${name}`;

    if (unit) {
      legendName += ` [${unit}]`;
    }

    if (tempRes != "custom") {
      legendName += `<br>${tempRes}`;
    } else {
      legendName += `<br>${tempRes}  ${aggregationStep} days`;
    }

    if (coordinates) {
      legendName += `<br>${coordinates.lat.toFixed(
        3
      )},${coordinates.lng.toFixed(3)}`;
    }

    if (unit) {
      legendName += `<br>`;
    }

    return legendName;
  },
  createVirtualStationLegendName({
    layerName,
    stationName,
    unit,
    tempRes,
    aggregationStep
  }) {
    if (tempRes != "custom") {
      var legendName = `Station: ${stationName}<br>${tempRes}<br> ${layerName}`;
    } else {
      legendName = `Station: ${stationName}<br>${tempRes} ${aggregationStep} days<br> ${layerName}`;
    }
    if (unit) {
      legendName += ` [${unit}]`;
    }
    return legendName;
  },
  createVectorLegendName({
    layerName,
    parameterName,
    stationName,
    unit,
    stationNameLabel
  }) {
    return `${layerName}<br>${parameterName} [${unit}]<br>${stationNameLabel}: ${stationName}`; //<br>${tempResNameLabel}: ${tempRes}
  },
  filterNullValues(datetime, values, objects) {
    const filtered_values = values.filter(x => !!x);
    const filtered_datetime = datetime.filter((x, i) => !!values[i]);
    return { x: filtered_datetime, y: filtered_values, steps: objects };
  }
};

export default PlotUtils;
