import { render, staticRenderFns } from "./LocalThresholdBox.vue?vue&type=template&id=a83bdd82&scoped=true"
import script from "./LocalThresholdBox.vue?vue&type=script&lang=js"
export * from "./LocalThresholdBox.vue?vue&type=script&lang=js"
import style0 from "./LocalThresholdBox.vue?vue&type=style&index=0&id=a83bdd82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a83bdd82",
  null
  
)

export default component.exports