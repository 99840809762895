<template>
  <v-card class="box" flat>
    <v-card-title>
      <v-icon :color="getStatusColor(status)">
        {{ getStatusIcon(status) }}
      </v-icon>
      <span class="ml-4">{{ $t(`thresholdSettings.${status}`) }}</span>
      <span
        class=" ml-3 mt-2 font-weight-light"
        style="font-size: 10px; align-self: end !important;"
        >{{ $t("thresholdSettings.regionThreshold") }}: Median
        {{ operatorItems.filter(item => item.value == regionOperator)[0].text }}
        {{ regionThreshold }}</span
      >
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <v-select
            v-model="selectedMeasure"
            :items="measureItems"
            :label="$t('thresholdSettings.stat')"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="selectedOperator"
            :items="operatorItems"
            :label="$t('thresholdSettings.operator')"
            @change="emitThreshold"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="threshold"
            :label="$t('thresholdSettings.threshold')"
            type="Number"
            step="0.1"
            @input="emitThreshold"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import alertMixin from "@/core/mixins/alert.mixin";

export default {
  name: "LocalThresholdBox",
  mixins: [alertMixin],
  props: {
    status: {
      type: String,
      required: true
    },
    regionThreshold: {
      type: Number
    },
    regionOperator: {
      type: String,
      default: "gt"
    },
    initialThreshold: {
      type: Number
    },
    initialOperator: {
      type: String,
      default: "gt"
    },
    option: {
      type: String,
      default: "region"
    }
  },
  data() {
    return {
      measureItems: [{ value: "median", text: "Median" }],
      selectedMeasure: "median",
      operatorItems: [
        { value: "gt", text: ">" },
        { value: "ge", text: "≥" },
        { value: "eq", text: "==" },
        { value: "le", text: "≤" },
        { value: "lt", text: "<" }
      ],
      selectedOperator: this.initialOperator,
      threshold: this.initialThreshold
    };
  },
  methods: {
    emitThreshold() {
      this.$emit(this.status, {
        operator: this.selectedOperator,
        threshold: parseFloat(this.threshold)
      });
    }
  }
};
</script>

<style scoped>
.box {
  background-color: white;
}
</style>
