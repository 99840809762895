<template>
  <v-row style="align-items: center">
    <v-col align="center">
      <v-card
        elevation="0"
        style="background-color: #E9EFF5"
        class="hidden-md-and-down rounded-circle text-center d-flex flex-column align-center justify-center"
        :width="cardSize"
        :height="cardSize"
        v-if="cardSize > 0"
        rounded
      >
        <v-icon v-if="option === 'region'" color="#A3B1C7" size="120"
          >mdi-bell</v-icon
        >
        <v-icon v-else class="ml-15" color="#A3B1C7" size="80"
          >mdi-bell
        </v-icon>
        <v-icon
          v-if="option === 'local'"
          class="mr-15"
          size="100"
          color="#A3B1C7"
          >mdi-vector-polygon
        </v-icon>
      </v-card>
      <p class="mt-5 text-h5">{{ $t("thresholdSettings.almostThere") }}</p>
      <p v-if="option == 'local' && thresholdProducts.length">
        {{ $t("thresholdSettings.pleaseAddThresholds") }}
      </p>
      <p v-if="option == 'local' && !thresholdProducts.length">
        {{ $t("thresholdSettings.firstAddRegionThreshold") }}
      </p>
      <p v-if="option == 'region'">
        {{ $t("thresholdSettings.pleaseAddRegionThresholds") }}
      </p>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#039BE5"
            rounded
            class="white--text font-weight-bold button-text"
            v-bind="attrs"
            v-on="on"
            :disabled="!thresholdProducts.length"
          >
            <span v-if="option == 'region'"
              >{{ $t("thresholdSettings.addRegionThreshold") }}
            </span>
            <span v-if="option == 'local'">{{
              $t("thresholdSettings.addLocalThreshold")
            }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="product in thresholdProducts"
            :key="product.layer"
            @click="emitCreateThreshold(product)"
          >
            <v-list-item-title>
              {{ product.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "emptyThresholdState",
  props: {
    thresholdProducts: Array,
    option: String
  },
  computed: {
    cardSize() {
      const height = window.innerHeight;
      if (height > 900) {
        return 320;
      } else if (height > 800) {
        return 300;
      } else if (height > 700) {
        return 250;
      } else {
        return 0;
      }
    }
  },
  methods: {
    emitCreateThreshold(product) {
      this.$emit("createThreshold", product);
    }
  }
};
</script>

<style scoped>
.button-text {
  text-transform: unset !important;
}
</style>
