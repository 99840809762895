<template>
  <v-row align="center" class="my-1">
    <!--    <v-row>-->
    <v-col cols="2" class="d-flex">
      <span class="text-h6">
        {{ title }}
        {{ getProductUnit(productThreshold.product) }}
        <v-btn icon @click="deleteLocalThreshold">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </span>
    </v-col>
    <v-col cols="5">
      <local-threshold-box
        status="alert"
        :region-operator="thresholdItem.global_thresholds.operator_alert"
        :region-threshold="thresholdItem.global_thresholds.threshold_alert"
        :initial-threshold="thresholdItem.local_thresholds.threshold_alert"
        :initial-operator="thresholdItem.local_thresholds.operator_alert"
        @alert="setAlert"
      />
    </v-col>
    <v-col cols="5">
      <local-threshold-box
        status="warning"
        :region-operator="thresholdItem.global_thresholds.operator_warning"
        :region-threshold="thresholdItem.global_thresholds.threshold_warning"
        :initial-threshold="thresholdItem.local_thresholds.threshold_warning"
        :initial-operator="thresholdItem.local_thresholds.operator_warning"
        @warning="setWarning"
      />
    </v-col>
    <!--    </v-row>-->
    <!--    <v-row class="my-5">-->
    <!--      <v-divider></v-divider>-->
    <!--    </v-row>-->
  </v-row>
</template>

<script>
import { productUnits } from "@/core/services/eomap.services";
import axios from "axios";
import LocalThresholdBox from "@/core/components/globalThreshold/LocalThresholdBox.vue";

export default {
  name: "localProductThreshold",
  components: { LocalThresholdBox },
  props: {
    title: {
      type: String
    },
    productThreshold: Object,
    updateThreshold: Array
  },
  data() {
    return {
      productUnits: productUnits,
      thresholdItem: this.productThreshold
    };
  },
  computed: {
    itemUrl() {
      return `/alerts/${this.productThreshold.id}/delete-local-thresholds/`;
    }
  },
  methods: {
    getProductUnit(product) {
      const productUnit = this.productUnits[product];
      if (productUnit) {
        return `[${productUnit}]`;
      }

      return "";
    },
    setWarning({ operator, threshold }) {
      this.thresholdItem.local_thresholds.threshold_warning = threshold;
      this.thresholdItem.local_thresholds.operator_warning = operator;
      this.$emit("madeChanges", this.thresholdItem.id);
    },
    setAlert({ operator, threshold }) {
      this.thresholdItem.local_thresholds.threshold_alert = threshold;
      this.thresholdItem.local_thresholds.operator_alert = operator;
      this.$emit("madeChanges", this.thresholdItem.id);
    },
    async updateLocalThreshold() {
      await axios.post(
        `/alerts/${this.productThreshold.id}/set-local-thresholds/`,
        {
          threshold_warning: this.thresholdItem.local_thresholds
            .threshold_warning,
          threshold_alert: this.thresholdItem.local_thresholds.threshold_alert,
          operator_warning: this.thresholdItem.local_thresholds
            .operator_warning,
          operator_alert: this.thresholdItem.local_thresholds.operator_alert
        }
      );
      this.$emit("reloadAlerts");
    },
    async deleteLocalThreshold() {
      await axios.delete(this.itemUrl);
      this.$emit("deleteItem", this.thresholdItem.id);
    }
  },
  watch: {
    updateThreshold() {
      if (this.updateThreshold.includes(this.thresholdItem.id)) {
        this.updateLocalThreshold();
      }
    }
  }
};
</script>

<style scoped></style>
