<template>
  <v-main>
    <div
      v-if="!loading"
      fluid
      class="fill-height overflow-x-hidden overflow-y-auto no-scrollbar px-10"
    >
      <!--RETURN-->
      <v-row>
        <v-col>
          <v-btn class="pl-0" text @click="goBack" style="color: #173466">
            <v-icon size="x-large">mdi-chevron-left</v-icon>
            <span class="text-body-1"> {{ $t("thresholdSettings.back") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <threshold-header
        v-if="activeRegion"
        :virtual-stations="virtualStations"
        @changeMode="changeMode"
        :region="activeRegion"
        @changeSelectedStation="changeSelectedStation"
      ></threshold-header>
      <v-divider class="mt-5"></v-divider>
      <v-divider class="mb-5"></v-divider>
      <region-threshold-edit-window
        v-if="thresholdOption === 'region'"
      ></region-threshold-edit-window>
      <local-threshold-edit-window
        :vs-station="vsStation"
        v-if="thresholdOption === 'local' && vsStation"
      ></local-threshold-edit-window>

      <empty-state-vs
        v-if="thresholdOption === 'local' && !virtualStations.length"
      ></empty-state-vs>
    </div>
  </v-main>
</template>

<script>
import ThresholdHeader from "@/core/components/globalThreshold/ThresholdHeader.vue";
import RegionThresholdEditWindow from "@/core/components/globalThreshold/RegionThresholdEditWindow.vue";
import LocalThresholdEditWindow from "@/core/components/globalThreshold/LocalThresholdEditWindow.vue";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import regionMixin from "@/core/mixins/region.mixin";
import EmptyStateVs from "@/core/components/EmptyStates/EmptyStateVS.vue";

export default {
  name: "ThresholdsSetttingsView",
  components: {
    EmptyStateVs,
    LocalThresholdEditWindow,
    RegionThresholdEditWindow,
    ThresholdHeader
  },
  mixins: [virtualStationMixin, regionMixin],
  data() {
    return {
      thresholdOption: "region",
      vsStation: undefined,
      virtualStations: null,
      loading: true
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    changeMode(val) {
      this.thresholdOption = val;
    },
    changeSelectedStation(val) {
      this.vsStation = val;
    }
  },
  async created() {
    const stations = await this.fetchVirtualStationsByRegion(
      this.$route.params.regionId
    );
    this.virtualStations = stations.sort((a, b) =>
      a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    );
    this.loading = false;
  }
};
</script>

<style scoped></style>
