<template>
  <v-container class="ma-0 overflow-y-auto" style=" max-width: 100%;">
    <v-row v-if="thresholdItems.length">
      <span class="text-h5 pr-3" :style="{ color: 'primary' }"
        >{{ $t("thresholdSettings.setThresholdsStation") }}:
      </span>
      <span class="text-h5 font-weight-bold" :style="{ color: 'primary' }">{{
        vsStation.name
      }}</span>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined color="primary" v-bind="attrs" v-on="on">
            {{ $t("thresholdSettings.addLocalThreshold") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="product in regionAlerts"
            :key="product.layer"
            @click="createLocalThreshold(product)"
          >
            <v-list-item-title>
              {{ getProductTitle(product.layer) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-container
      class="mt-5 mb-8 full-height overflow-x-hidden overflow-y-auto no-scrollbar"
    >
      <v-row class="py-5" v-if="!loading">
        <empty-threshold-state
          :option="'local'"
          :thresholdProducts="regionAlerts"
          @createThreshold="createLocalThreshold"
          v-if="!thresholdItems.length"
        ></empty-threshold-state>
        <local-product-threshold
          v-for="productThreshold in thresholdItems"
          :key="productThreshold.id"
          :title="getProductTitle(productThreshold.layer)"
          :product-threshold="productThreshold"
          :update-threshold="updatedThresholds"
          @madeChanges="addSaveNeeded"
          @reloadAlerts="getAlertsForVS"
          @deleteItem="loadDataForVS"
        />
      </v-row>
    </v-container>
    <v-row class="justify-end" v-if="thresholdItems.length">
      <v-btn color="primary" text @click="$router.go(-1)" class="pr-6">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        :disabled="!saveAvailable"
        color="primary"
        @click="triggerSave"
        width="221px"
      >
        {{ $t("thresholdSettings.saveChanges") }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import EmptyThresholdState from "@/core/components/globalThreshold/emptyThresholdState.vue";
import LocalProductThreshold from "@/core/components/globalThreshold/localProductThreshold.vue";

export default {
  name: "LocalThresholdEditWindow",
  components: { LocalProductThreshold, EmptyThresholdState },
  props: {
    vsStation: {}
  },
  data: () => ({
    thresholdItems: [],
    regionAlerts: [],
    loading: true,
    saveNeeded: new Set(),
    saveAvailable: false,
    updatedThresholds: []
  }),
  computed: {
    ...mapState("management", ["accessToken"]),
    ...mapState("dashboard", ["availableProducts", "regionThresholdItems"])
  },
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    triggerSave() {
      this.updatedThresholds = Array.from(this.saveNeeded);
      this.saveNeeded = new Set();
      this.saveAvailable = false;
    },
    addSaveNeeded(value) {
      this.saveNeeded.add(value);
      if (this.saveNeeded.size > 0) {
        this.saveAvailable = true;
      }
    },
    getProductTitle(layer) {
      const result = this.availableProducts.filter(
        product => product.layerId === layer
      );
      if (result.length === 0) {
        console.log(`No layer with id ${layer} available.`);
      }
      return result[0].title;
    },

    getRegionProductThreshold(product) {
      const regionProductThreshold = this.regionThresholdItems.filter(
        threshold => threshold.product === product
      );
      return regionProductThreshold[0];
    },

    async createLocalThreshold(alert) {
      const regionThreshold = this.getRegionProductThreshold(alert.product);
      await axios.post(`/alerts/${alert.id}/set-local-thresholds/`, {
        threshold_warning: regionThreshold.threshold_warning,
        threshold_alert: regionThreshold.threshold_alert,
        operator_warning: regionThreshold.operator_warning,
        operator_alert: regionThreshold.operator_alert
      });
      this.loadDataForVS();
    },
    filterExistingThresholdFromList(regionAlerts, localThresholds) {
      if (localThresholds.length) {
        const filterAlert = this.regionAlerts.filter(alert =>
          localThresholds.some(threshold => {
            return threshold.product !== alert.product;
          })
        );
        this.regionAlerts = filterAlert;
      }
    },
    async loadDataForVS() {
      this.loading = true;
      await this.getAlertsForVS();
      this.addProductTitel(this.regionAlerts);
      this.getLocalThresholds();
      this.filterExistingThresholdFromList(
        this.regionAlerts,
        this.thresholdItems
      );
      this.loading = false;
    },

    getLocalThresholds() {
      const filteredAlerts = this.regionAlerts.filter(alert => {
        if (alert.local_thresholds !== null) {
          return true;
        }
      });
      this.thresholdItems = filteredAlerts;
    },

    async getAlertsForVS() {
      const response = await axios.get(
        `/alerts/virtual-station/${this.vsStation.id}/`
      );
      this.regionAlerts = response.data;
    },

    addProductTitel(alerts) {
      alerts.forEach(item => {
        item.title = this.getProductTitle(item.layer);
      });
    }
  },
  watch: {
    async vsStation() {
      this.loadDataForVS();
    }
  },
  async created() {
    this.loadDataForVS();
  }
};
</script>

<style scoped>
.full-height {
  height: calc(70vh - 200px) !important;
}

.no-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(8, 8, 8, 0.2);
  border-radius: 8px;
}
</style>
