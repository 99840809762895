<template>
  <v-row align="center" class="my-1">
    <!--    <v-row>-->
    <v-col cols="2" class="d-flex">
      <span class="text-h6">
        {{ title }}
        {{ getProductUnit(productThreshold.product) }}
        <v-btn icon @click="deleteGlobalThreshold">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </span>
    </v-col>
    <v-col cols="5">
      <threshold-box
        status="alert"
        :initial-threshold="thresholdItem.threshold_alert"
        :initial-operator="thresholdItem.operator_alert"
        @alert="setAlert"
      />
    </v-col>
    <v-col cols="5">
      <threshold-box
        status="warning"
        :initial-threshold="thresholdItem.threshold_warning"
        :initial-operator="thresholdItem.operator_warning"
        @warning="setWarning"
      />
    </v-col>
    <!--    </v-row>-->
    <!--    <v-row class="my-5">-->
    <!--      <v-divider></v-divider>-->
    <!--    </v-row>-->
  </v-row>
</template>

<script>
import ThresholdBox from "@/core/components/globalThreshold/ThresholdBox";
import { productUnits } from "@/core/services/eomap.services";
import axios from "axios";

export default {
  name: "ProductThreshold",
  components: { ThresholdBox },
  props: {
    title: {
      type: String
    },
    productThreshold: {
      type: Object,
      required: true,
      default: () => ({
        product: "",
        threshold_warning: 0,
        threshold_alert: 0,
        operator_warning: "gt",
        operator_alert: "gt",
        id: 0
      })
    },
    updateThreshold: Array
  },
  data() {
    return {
      productUnits: productUnits,
      thresholdItem: this.productThreshold
    };
  },
  computed: {
    itemUrl() {
      return `/alerts/region/${this.$route.params.regionId}/global-thresholds/${this.thresholdItem.id}/`;
    }
  },
  methods: {
    getProductUnit(product) {
      const productUnit = this.productUnits[product];
      if (productUnit) {
        return `[${productUnit}]`;
      }

      return "";
    },
    setWarning({ operator, threshold }) {
      this.thresholdItem.threshold_warning = threshold;
      this.thresholdItem.operator_warning = operator;
      this.$emit("madeChanges", this.thresholdItem.id);
    },
    setAlert({ operator, threshold }) {
      this.thresholdItem.threshold_alert = threshold;
      this.thresholdItem.operator_alert = operator;
      this.$emit("madeChanges", this.thresholdItem.id);
    },
    updateGlobalThreshold() {
      axios.put(this.itemUrl, this.thresholdItem);
    },
    deleteGlobalThreshold() {
      axios.delete(this.itemUrl);
      this.$emit("deleteItem", this.thresholdItem.id);
    }
  },
  watch: {
    updateThreshold() {
      if (this.updateThreshold.includes(this.thresholdItem.id)) {
        this.updateGlobalThreshold();
      }
    }
  }
};
</script>

<style scoped></style>
