<template>
  <v-container class="ma-0 overflow-y-auto" style=" max-width: 100%;">
    <v-row class="justify-end" v-if="thresholdItems.length">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="primary"
            v-bind="attrs"
            v-on="on"
            :disabled="!createThresholdProducts.length"
          >
            {{ $t("thresholdSettings.addRegionThreshold") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="product in createThresholdProducts"
            :key="product.layer"
            @click="createGlobalThreshold(product)"
          >
            <v-list-item-title>
              {{ product.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-container
      class="mt-5 mb-8  full-height overflow-x-hidden overflow-y-auto no-scrollbar"
    >
      <v-row class="py-5" v-if="!loading">
        <empty-threshold-state
          :option="'region'"
          :threshold-products="availableProducts"
          :createThresholdProducts="createThresholdProducts"
          @createThreshold="createGlobalThreshold"
          v-if="!thresholdItems.length"
        ></empty-threshold-state>
        <product-threshold
          v-else
          v-for="productThreshold in thresholdItems"
          :key="productThreshold.id"
          :title="getProductTitle(productThreshold.layer)"
          :product-threshold="productThreshold"
          :update-threshold="updatedThresholds"
          @madeChanges="addSaveNeeded"
          @deleteItem="removeProductFromThresholds"
        />
      </v-row>
    </v-container>
    <v-row class="justify-end" v-if="thresholdItems.length">
      <v-btn color="primary" text @click="$router.go(-1)" class="pr-6">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        :disabled="!saveAvailable"
        color="primary"
        @click="triggerSave"
        width="221px"
      >
        {{ $t("thresholdSettings.saveChanges") }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import ProductThreshold from "@/core/components/globalThreshold/ProductThreshold.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";
import EmptyThresholdState from "@/core/components/globalThreshold/emptyThresholdState.vue";

export default {
  name: "RegionThresholdEditWindow",
  components: { EmptyThresholdState, ProductThreshold },
  data: () => ({
    thresholdItems: [],
    availableProducts: [],
    loading: true,
    saveNeeded: new Set(),
    saveAvailable: false,
    updatedThresholds: []
  }),
  computed: {
    ...mapState("management", ["accessToken"]),
    createThresholdProducts() {
      const existingThresholdLayerIds = this.thresholdItems.map(
        item => item.layer
      );
      return this.availableProducts.filter(
        product => !existingThresholdLayerIds.includes(product.layerId)
      );
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("dashboard", ["setAvailableProducts", "setThresholdItems"]),
    triggerSave() {
      this.updatedThresholds = Array.from(this.saveNeeded);
      this.saveNeeded = new Set();
      this.saveAvailable = false;
    },
    addSaveNeeded(value) {
      this.saveNeeded.add(value);
      if (this.saveNeeded.size > 0) {
        this.saveAvailable = true;
      }
    },
    async fetchAvailableProducts() {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${this.$route.params.regionId}&token=${this.accessToken}`
      );
      response.data = response.data.sort((a, b) =>
        a.title.toUpperCase().localeCompare(b.title.toUpperCase())
      );
      const availableProducts = response.data
        .filter(product => product.product !== "rgb")
        .filter(product => product.product !== "qut");
      if (this.$appConfig.keycloakClient == "coasts") {
        this.availableProducts = availableProducts
          .filter(item => !item.product.includes("change"))
          .filter(layer => layer.product !== "wvh")
          .filter(layer => layer.product !== "esc");
      } else {
        this.availableProducts = availableProducts;
      }
      this.setAvailableProducts(this.availableProducts);
    },

    getProductTitle(layer) {
      const result = this.availableProducts.filter(
        product => product.layerId === layer
      );
      if (result.length === 0) {
        console.log(`No layer with id ${layer} available.`);
      }
      return result[0].title;
    },

    async fetchGlobalThresholds() {
      let response = await axios.get(
        `/alerts/region/${this.$route.params.regionId}/global-thresholds/`
      );
      this.thresholdItems = response.data;
      this.setThresholdItems(this.thresholdItems);
    },

    async createGlobalThreshold(product) {
      const response = await axios.post(
        `/alerts/region/${this.$route.params.regionId}/global-thresholds/`,
        {
          product: product.product,
          layer: product.layerId,
          threshold_warning: null,
          threshold_alert: null
        }
      );
      this.thresholdItems.unshift(response.data);
    },
    removeProductFromThresholds(thresholdId) {
      this.thresholdItems = this.thresholdItems.filter(
        item => item.id !== thresholdId
      );
    }
  },
  created() {
    this.getOrFetchAccessToken().then(() => {
      this.fetchAvailableProducts().then(() => {
        this.fetchGlobalThresholds().then(() => {
          this.loading = false;
        });
      });
    });
  }
};
</script>

<style scoped>
.full-height {
  height: calc(70vh - 200px) !important;
}

.no-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(8, 8, 8, 0.2);
  border-radius: 8px;
}
</style>
